import React from 'react';
import ReactDOM from 'react-dom/client';

const enableMocking = async () => {
  if (process.env.NODE_ENV !== 'development') return {};
  const { worker } = await import('__tests__/api/mocks/browser');
  return worker.start();
};

const importBuildTarget = async () => {
  if (process.env.REACT_APP_BUILD_TARGET === 'SCC_ADMIN') {
    return import('./sccAdmin/App')
      .then(({ App }) => App)
      .catch((error) =>
        Promise.reject(new Error('Error building the admin app', error))
      );
  }
  if (process.env.REACT_APP_BUILD_TARGET === 'COMPANY_ADMIN') {
    return import('./companyAdmin/App')
      .then(({ App }) => App)
      .catch((error) =>
        Promise.reject(new Error('Error building the company-admin app', error))
      );
  }
  return Promise.reject(
    new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`)
  );
};

enableMocking().then(() => {
  importBuildTarget().then((App) => {
    if (App) {
      const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
      );
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }
  });
});
